<template>
  <div class="company-serve">
    <img
      class="company-serve-pc"
      src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/companyServePc.jpg"
      alt=""
    />
    <div class="company-serve-pc-f" @click="lookImg">
      <img
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/companyServePcF.png"
        alt=""
      />
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="600px"
      :modal-append-to-body="false"
    >
      <div class="dialog-company-serve">
          <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/companyServePcF.png" alt="">
          <div @click="dialogVisible = false"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "companyServe",

  data() {
    return {
        dialogVisible: false,
    };
  },

  mounted() {},

  methods: {
    lookImg() {
        this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.company-serve{
    /deep/.el-dialog__header{
        display: none;
    }
    /deep/.el-dialog__body{
        padding: 0;
    }
    /deep/.el-dialog, .el-pager li {
        background: transparent;
    }
}
.company-serve-pc {
  width: 100%;
  height: auto;
}
.company-serve-pc-f {
  width: 200px;
  position: fixed;
  height: 160px;
  right: 20px;
  top: 60%;
  overflow: hidden;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
}
.dialog-company-serve{
    position: relative;
    width: 100%;
    img{
        width: 100%;
        height: auto;
    }
    div{
        width: 300px;
        height: 80px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        cursor: pointer;
    }
}
</style>